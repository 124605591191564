import { useEffect, useState } from 'react';
import { IMedia } from '@alterpage/gatsby-source-alterpress';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { getMediaImage, IMediaImage } from './utils';

export interface IUseMediaConfig {
    media: IMedia[] | undefined;
    relation?: string;
    fallback?: string;
    useFallback?: boolean;
}

type TUseMediaReturn = [IGatsbyImageData | undefined, string, string, string] &
    Partial<IMediaImage>;

export const useMedia = ({
    media,
    relation,
    fallback,
    useFallback = true,
}: IUseMediaConfig): TUseMediaReturn => {
    const [mediaImage, setMediaImage] = useState<IMediaImage>(
        getMediaImage({ media, relation, fallback, useFallback })
    );

    useEffect(() => {
        setMediaImage(getMediaImage({ media, relation, fallback, useFallback }));
    }, [fallback, media, relation, useFallback]);

    const mediaArray: TUseMediaReturn = [
        mediaImage.image,
        mediaImage.alt,
        mediaImage.url,
        mediaImage.caption,
    ];
    mediaArray.image = mediaImage.image;
    mediaArray.alt = mediaImage.alt;
    mediaArray.url = mediaImage.url;
    mediaArray.caption = mediaImage.caption;

    return mediaArray;
};
