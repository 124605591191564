import React from 'react';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';

import {
    container,
    buttonAdd,
    buttonAddInner,
    field,
    deleteBox,
    deleteButton,
    brandInput,
    budgetInput,
    ownerInput,
} from './brand-field-array.module.scss';
import PlusIcon from '../../assets/images/svg/plus.svg';
import DeleteIcon from '../../assets/images/svg/circledelete.svg';
import { IOption } from '../../models/option.model';
import { IActivationBrandValue } from '../../models/activation.model';
import useTranslations from '../../hooks/use-translations';

import Button from './button';
import Select from './select';
import Input from './input';
import Error from './error';
import MultiInput from './multi-input';

interface IBrandFieldArrayProps {
    className?: string;
    name: string;
    options: IOption[];
}

const MAX_BRANDS_COUNT = 6;

export const brandEmptyValue: IActivationBrandValue = {
    brandId: null,
    budgetPercent: null,
    ownerEmail: null,
};

const BrandFieldArray: React.FC<IBrandFieldArrayProps> = ({ className = '', name, options }) => {
    const t = useTranslations('BrandFieldArray');

    const [, meta] = useField<IActivationBrandValue[]>(name);
    const brands = meta.value || [];

    const handleAdd = (helpers: FieldArrayRenderProps) => {
        return () => {
            helpers.push(brandEmptyValue);
        };
    };

    const handleRemove = (helpers: FieldArrayRenderProps, index: number) => {
        return () => {
            if (brands.length === 1) {
                helpers.replace(index, brandEmptyValue);
            } else {
                helpers.remove(index);
            }
        };
    };

    return (
        <FieldArray name={name}>
            {(helpers) => (
                <div className={`${container} ${className}`}>
                    {brands.map((brand, index) => {
                        const selectedOptionValue = brands[index]?.brandId;
                        const selectOptions = options.filter(
                            (option) =>
                                option.value === selectedOptionValue ||
                                brands.every((brand) => brand.brandId !== option.value)
                        );
                        return (
                            <div key={`brand-${brand.brandId}-${index}`} className={field}>
                                <Select
                                    className={brandInput}
                                    label={t.brand.label}
                                    name={`${name}.${index}.brandId`}
                                    options={selectOptions}
                                    isMulti={false}
                                    placeholder={t.brand.placeholder}
                                    hideSelectedOptions={true}
                                />
                                <Input
                                    className={budgetInput}
                                    label={t.budgetPercent.label}
                                    type="number"
                                    name={`${name}.${index}.budgetPercent`}
                                    placeholder={t.budgetPercent.placeholder}
                                />
                                <MultiInput
                                    className={ownerInput}
                                    label={t.owner.label}
                                    name={`${name}.${index}.ownerEmail`}
                                    placeholder={t.owner.placeholder}
                                />
                                <div className={deleteBox}>
                                    <button
                                        className={deleteButton}
                                        type="button"
                                        onClick={handleRemove(helpers, index)}
                                    >
                                        {t.delete} <DeleteIcon />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    {meta.touched && meta.error && typeof meta.error === 'string' && (
                        <Error name={name} />
                    )}
                    {brands.length < MAX_BRANDS_COUNT && (
                        <Button type="button" className={buttonAdd} onClick={handleAdd(helpers)}>
                            <span className={buttonAddInner}>
                                <PlusIcon />
                                <span>{t.add}</span>
                            </span>
                        </Button>
                    )}
                </div>
            )}
        </FieldArray>
    );
};

export default BrandFieldArray;
