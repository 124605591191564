const pl = {
    ActiveDiscountCode: {
        delete: 'Usuń kod rabatowy',
    },
    Activation: {
        title: 'New Activation',
        stepOne: 'Step 1',
        stepTwo: 'Step 2',
        stepThree: 'Step 3',
        seeding: 'Konkurs z seedingiem',
        noSeeding: 'Konkurs bez seedingu',
    },
    ActivationTaskFieldArray: {
        type: 'Task Type',
        duration: 'Task Duration (days)',
        start: 'Start Date',
        end: 'End Date',
        to: 'to',
        day: 'day',
        days: 'days',
        otherActivations: (count: number) =>
            `There are ${count} other activations live in Beauty coLAB on this period.`,
    },
    ActivationProductFieldArray: {
        name: 'Product Name',
        code: 'EAN',
        size: 'Product Size',
        capacity: 'Capacity in g/ml',
        price: 'Market Price in PLN',
        description: 'Product description',
        tax: 'Product Tax (11,1%) in PLN',
        image: 'Product Picture',
        imageSubtext: 'JPG, PNG (Max <b>2MB</b>)',
        label: 'Product',
        delete: 'Delete',
        add: 'Add product',
        minCount: 'At least one product is required',
    },
    ActivationProductModal: {
        title: 'Are you sure you want to <span>delete</span> this product?',
        description: "This item will be deleted immediately. You can't undo this action",
        cancel: 'Cancel',
        delete: 'Yes, delete',
    },
    ActivationDeleteModal: {
        title: 'Are you sure you want to <span>delete</span> this activation?',
        description: "This activation will be deleted immediately. You can't undo this action",
        cancel: 'Cancel',
        delete: 'Yes, delete',
    },
    ActivationRewardFieldArray: {
        name: {
            label: 'Reward Name',
            placeholder: 'Fill',
        },
        price: {
            label: 'Reward Market Value in PLN',
            placeholder: 'Fill',
        },
        taxName: {
            label: 'Vat',
            placeholder: 'Select',
        },
        tax: 'Reward Tax (11,1%) in PLN',
        image: 'Reward Picture',
        imageSubtext: 'JPG, PNG (Max <b>2MB</b>)',
        label: 'Reward',
        delete: 'Delete',
        add: 'Add reward',
        minCount: 'At least one reward is required',
    },
    ActivationStepOneForm: {
        division: {
            label: 'Division',
            placeholder: 'Select',
            error: 'Please, choose a division of your activation',
        },
        brand: {
            label: 'Brand',
            placeholder: 'Select',
            error: 'Please, choose a brand of your activation',
        },
        brands: {
            error: {
                min: 'The percentage share in the budget cannot be less than 0',
                max: 'The percentage share in the budget could not be greater than 100',
                sum: 'The sum of brand budgets must be 100%',
                noBudget: 'Enter budget for all selected brands',
                email: 'Please, type in an correct email',
                noOwner: 'Please, type in an owner email address',
            },
        },
        type: {
            label: 'Activation Type',
            placeholder: 'Select',
            error: 'Please, choose an activation type',
        },
        scenario: {
            label: 'Task Scenario',
            placeholder: 'Select',
            error: 'Please, choose a scenario for your activation',
        },
        concept: {
            label: 'Unique Concept',
            placeholder: 'Fill',
            error: 'Please, type in an unique concept of your activation',
            note: '(ex. Buttermelt Blush, Loveshine lipstick etc., Green ON event etc. )',
        },
        club: {
            label: 'User Group/Club',
            placeholder: 'Select',
            error: 'Please, choose a group/club of your activation',
        },
        customClub: {
            placeholder: 'Fill the name of the Group/Club',
            error: 'Please, fill the name of the group/club',
            max: 'This field cannot contain more than 128 characters',
        },
        name: {
            label: 'Contest Name',
        },
        focus: {
            max: 'This field cannot contain more than 128 characters',
        },
        displayName: {
            label: 'Display Name',
            placeholder: 'Fill',
            error: 'Please, type in a display name for you activation',
            note: '(Unique activation name that will appear on the activation list, ex. NYX PM contest - Buttermelt Blushes)',
            max: 'This field cannot contain more than 128 characters',
        },
        submit: {
            label: 'Save',
        },
        nextStep: {
            label: 'Go to next step',
        },
        tasks: {
            required: 'This field is required',
            minDuration: (days: number) => `This task must last at least ${days} days`,
            maxDuration: 'Tasks cannot last longer than 32 days',
        },
        dateFromPast: 'It can not be a date from the past',
        success: 'Activation has been successfully saved',
        error: 'Please correct errors in marked fields',
    },
    ActivationStepTwoSeedForm: {
        packages: {
            label: 'Number of packages',
            error: {
                required: 'Please, type in number of packages',
                type: 'Please, type in a correct number',
            },
            placeholder: 'Fill',
        },
        products: {
            label: 'Number of products in 1 package',
            name: {
                required: 'Product name is required',
            },
            hint: 'Number of products in one package will change once a new product is added below.',
            image: {
                required: 'Product image is required',
            },
        },
        type: {
            label: 'Packaging Type',
            error: 'Please, select a packaging type',
            placeholder: 'Select',
        },
        cost: {
            label: 'Cost per Package (logistics cost net) in PLN',
            hint: 'Cost Per Package =  delivery & repackaging cost (20 PLN) + insert cost (3 PLN for each insert, counted from the 2nd insert) + packaging cost <br><br> ex. for 1 package with 2 products in cardboard packaging option = 20 PLN + 3 PLN + 3,75 PLN = 26,75 PLN',
        },
        totalCost: {
            label: 'Total Cost (logistics cost net) in PLN',
        },
        included: {
            label: 'Which products should be included in 1 package?',
            placeholder:
                'Ex. NYX PM Fat Oil OR NYX PM Brow Glue at random; NYX PM Fat Oil AND NYX PM Brow Glue',
            required: 'This field is required',
        },
        submit: {
            label: 'Save',
        },
        prevStep: {
            label: 'Back',
        },
        nextStep: {
            label: 'Go to next step',
        },
        success: 'Activation has been successfully saved',
        error: 'Please correct errors in marked fields',
    },
    ActivationStepTwoNoSeedForm: {
        users: {
            label: 'Number of Users that can be Rewarded',
            placeholder: 'Fill',
            error: 'Please, type in a correct number',
        },
        rewards: {
            label: 'Number of Rewards',
            name: {
                required: 'Reward name is required',
            },
            hint: 'Number of rewards will change once a new reward is added below.',
            image: {
                required: 'Reward image is required',
            },
        },
        taxable: {
            label: 'Taxable Reward',
            placeholder: 'Select',
            yes: 'Yes',
            no: 'No',
        },
        submit: {
            label: 'Save',
        },
        prevStep: {
            label: 'Back',
        },
        nextStep: {
            label: 'Go to next step',
        },
        success: 'Activation has been successfully saved',
        error: 'Please correct errors in marked fields',
    },
    ActivationStepThreeForm: {
        comment: {
            label: 'Additional Comment',
            placeholder: 'Write a comment',
        },
        submit: {
            label: 'Save',
        },
        prevStep: {
            label: 'Back',
        },
        nextStep: {
            label: 'Send for verification',
        },
        success: 'Activation has been successfully saved',
    },
    ActivationDetails: {
        title: 'Dashboard',
        back: 'Back to list',
        innerTitle: 'Activation details',
        hint: 'This is your activation status. If you have any questions to your status please contact Beauty coLAB project leader.',
        edit: 'Edit',
        delete: 'Delete',
        deleteError: 'Failed to delete the activation',
        createdAt: 'Creation Date',
        step: 'Step',
        owner: 'Activation owner (e-mail)',
        type: {
            label: 'Activation Type',
            seeding: 'Konkurs z seedingiem',
            noSeeding: 'Konkurs bez seedingu',
        },
        task: 'Task Scenario',
        focus: 'Unique concept',
        division: 'Disivsion',
        brand: 'Brand',
        club: 'User Group/Club',
        packagesCount: 'Number of packages',
        productsCount: 'Number of products in 1 package',
        usersCount: 'Number of Users that can be Rewarded',
        taxableReward: 'Taxable Reward',
        tasks: 'Tasks',
        taskType: 'Task Type',
        taskDuration: 'Task duration',
        taskStart: 'Start Date',
        taskEnd: 'End Date',
        rewards: 'Rewards',
        rewardsCount: 'Number of Rewards',
        rewardPicture: 'Reward Picture',
        rewardName: 'Reward Name',
        rewardValue: 'Reward Market Value in PLN',
        rewardTax: 'Vat',
        rewardTaxValue: 'Reward Tax (11,1%)',
        products: 'Products',
        packageType: 'Packaging Type',
        productsIncluded: 'Products included in 1 package',
        packageProductCost: 'Cost per Package (logistics cost net)',
        totalProductCost: 'Total Cost (logistics cost net)',
        yes: 'Yes',
        no: 'No',
        start: 'Start Date',
        end: 'End Date',
        displayName: 'Display name',
        name: 'Contest name',
        template: 'Template',
        comment: 'Your Additional Comment',
        status: {
            new: 'New',
            waiting: 'Waiting for approval',
            approvedWaiting: 'Approved waiting for copy',
            approvalCopy: 'Waiting for copy approval',
            approved: 'Approved with all documents',
            request: 'Request for improvement',
            rejected: 'Rejected',
            waitingForSeedingVerification: 'Waiting for seeding verification',
            seedingVerified: 'Seeding verified',
        },
        button: {
            download: 'Download PDF',
            preview: 'Preview copy',
            complete: 'Complete task copy',
        },
        notCompleted: 'Not Completed',
        completed: 'Completed',
        deleted: 'Activation has been successfully deleted.',
        downloadError: 'Failed to download this file',
        verify: 'Verify seeding',
    },
    ActivationDetailsProduct: {
        picture: 'Product Picture',
        name: 'Product Name',
        ean: 'EAN',
        size: 'Product Size',
        capacity: 'Capacity in g/ml',
        value: 'Market Price',
        tax: 'Product Tax (11,1%)',
    },
    ActivationsList: {
        title: 'Dashboard',
        innerTitle: 'Activation List',
        date: 'Creation date',
        name: 'Activation Name',
        step: 'Step',
        statusLabel: 'Status',
        details: 'Details',
        empty: 'Activation list is empty.',
        id: 'id',
        status: {
            new: 'New',
            waiting: 'Waiting for approval',
            approvedWaiting: 'Approved waiting for copy',
            approvalCopy: 'Waiting for copy approval',
            approved: 'Approved with all documents',
            request: 'Request for improvement',
            rejected: 'Rejected',
            waitingForSeedingVerification: 'Waiting for seeding verification',
            seedingVerified: 'Seeding verified',
        },
        downloadError: 'Failed to download the file',
        filter: 'Filter by:',
    },
    ActivationPanelMobile: {
        info: 'Sorry, this website only works on',
        infoContinued: 'desktop',
    },
    ActivationPreview: {
        title: 'Dashboard',
        innerTitle: 'Activation details',
        hint: 'This is your activation status. If you have any questions to your status please contact Beauty coLAB project leader.',
        edit: 'Edit',
        delete: 'Delete',
        deleteError: 'Failed to delete the activation',
        createdAt: 'Creation Date',
        step: 'Step',
        owner: 'Activation owner (e-mail)',
        type: {
            label: 'Activation Type',
            seeding: 'Konkurs z seedingiem',
            noSeeding: 'Konkurs bez seedingu',
        },
        task: 'Task Scenario',
        focus: 'Unique concept',
        division: 'Disivsion',
        brand: 'Brand',
        club: 'User Group/Club',
        packagesCount: 'Number of packages',
        productsCount: 'Number of products in 1 package',
        usersCount: 'Number of Users that can be Rewarded',
        taxableReward: 'Taxable Reward',
        tasks: 'Tasks',
        taskType: 'Task Type',
        taskDuration: 'Task duration',
        taskStart: 'Start Date',
        taskEnd: 'End Date',
        rewards: 'Rewards',
        rewardsCount: 'Number of Rewards',
        rewardPicture: 'Reward Picture',
        rewardName: 'Reward Name',
        rewardValue: 'Reward Market Value in PLN',
        rewardTax: 'Vat',
        rewardTaxValue: 'Reward Tax (11,1%)',
        products: 'Products',
        productPicture: 'Product Picture',
        productName: 'Product Name',
        productSize: 'Product Size',
        productCapacity: 'Capacity in g/ml',
        productValue: 'Market Price',
        productTax: 'Product Tax (11,1%)',
        ean: 'EAN',
        packageType: 'Packaging Type',
        productsIncluded: 'Products included in 1 package',
        packageProductCost: 'Cost per Package (logistics cost net)',
        totalProductCost: 'Total Cost (logistics cost net)',
        yes: 'Yes',
        no: 'No',
        start: 'Start Date',
        end: 'End Date',
        displayName: 'Display name',
        name: 'Contest name',
        template: 'Template',
        comment: 'Your Additional Comment',
        status: {
            new: 'New',
            waiting: 'Waiting for approval',
            approvedWaiting: 'Approved waiting for copy',
            approvalCopy: 'Waiting for copy approval',
            approved: 'Approved with all documents',
            request: 'Request for improvement',
            rejected: 'Rejected',
        },
        button: {
            download: 'Download PDF',
            preview: 'Preview copy',
            complete: 'Complete task copy',
        },
        notCompleted: 'Not Completed',
        completed: 'Completed',
        deleted: 'Activation has been successfully deleted.',
        downloadError: 'Failed to download this file',
        verify: 'Verify seeding',
    },
    ActivationSeedingVerification: {
        title: 'Seeding verification',
        products: 'Products',
        packagesCount: 'Number of packages',
        productsCount: 'Number of products in 1 package',
        packageType: 'Packaging Type',
        productsIncluded: 'Products included in 1 package',
        packageProductCost: 'Cost per Package (logistics cost net)',
        totalProductCost: 'Total Cost (logistics cost net)',
        back: 'Back',
        save: 'Save',
        verifiedPackagesCount: {
            label: 'Number of delivered packages',
            placeholder: 'Fill',
            error: {
                min: 'The number of packages delivered cannot be less than zero',
                max: 'The number of packages delivered cannot exceed the number of packages in the activation',
            },
        },
        dedNumber: {
            label: 'DED number for logistics cost',
            placeholder: 'Fill',
            error: {
                required: 'Enter DED number',
            },
        },
    },
    ActivationSummary: {
        title: 'Activation Summary',
        seeding: 'Seeding',
        rewards: 'Other taxable rewards',
        diamonds: 'Rewards in diamonds',
        brandBudget: 'Brand budget',
        cdmoBudget: 'CDMO Budget',
        prices: {
            allActivationRewardsInDiamonds: 'All Activation Rewards in Diamonds',
            allActivationRewardsInPlnGross: 'All Activation Rewards in PLN gross',
            allActivationRewardsInPlnNet: 'All Activation Rewards in PLN net',
            aveMainReward: 'AVE. Value of SoMe Publication Reward in Beauty coLAB (Main Reward)',
            aveSecondaryReward:
                'AVE. Value of SoMe Publication Reward in Beauty coLAB (Secondary Reward)',
            logisticsCostForAllPackagesGross: 'Logistics Cost for All Packages gross',
            logisticsCostForAllPackagesNet: 'Logistics Cost for All Packages net',
            numberOfMainRewards: 'Number of Main Reward',
            numberOfSecondaryRewards: 'Number of Secondary Reward',
            productMarketValueInOnePackage: 'Product Market Value in 1 Package',
            productsTaxCostForAllPackages: 'Products Tax Cost for All Packages',
            questionnaireRewardInTotalNet: 'Questionnaire Reward in Total net',
            quizRewardInTotalNet: 'Quiz Reward in Total net',
            remainingBudgetNet: 'Remaining budget net',
            remainingBudgetGross: 'Remaining budget gross',
            rewardMarketValueGross: 'Reward Market Value gross',
            rewardMarketValueNet: 'Reward Market Value net',
            rewardTax: 'Reward Tax (11,1%)',
            suggestedSeedingCoefficient: '(Suggested Beauty coLAB seeding coefficient)',
            sumGross: 'Sum gross',
            sumNet: 'Sum net',
            taskWithPublicationMainRewardInTotalNet:
                'Task With Publication Main Reward in Total net',
            taskWithPublicationMainRewardNet: 'Task With Publication Main Reward net',
            taskWithPublicationSecondaryRewardInTotalNet:
                'Task With Publication Secondary Reward in Total net',
            taskWithPublicationSecondaryRewardNet: 'Task With Publication Secondary Reward net',
            totalProductMarketValueInAllPackages: 'Total Product Market Value in all Packages',
            totalProductTaxInOnePackage: 'Total Product Tax in 1 Package',
            totalRewardCostGross: 'Other Taxable Rewards gross',
            totalRewardCostNet: 'Other Taxable Rewards net',
            totalRewardMarketValueGross: 'Total Reward Market Value gross',
            totalRewardMarketValueNet: 'Total Reward Market Value net',
            totalSeedingCostForActivationGross: 'Total Seeding Cost for activation gross',
            totalSeedingCostForActivationNet: 'Total Seeding Cost for activation net',
            totalRewardTax: 'Total Reward Tax (11,1%)',
            logisticsInfo: '(Logistics Cost for All Packages + Products Tax Cost for All Packages)',
            totalInfo: '(Total Seeding Cost for activation + Total Rewards Cost in PLN)',
            coefficient: '(Suggested Beauty coLAB seeding coefficient)',
            coefficientHint: (values: string) =>
                `<span>Suggested Beauty coLAB seeding coefficient</span> <br> ${values}`,
            numberOfMainRewardsHint: (value: string) => `${value}% of all packages `,
            taskWithPublicationMainRewardNetHint: (value: string) =>
                `Calculated based on below formula: AVE. Value of SoMe Publication Reward in Beauty coLAB (Main Reward) - (Product Market Value in 1 Package * Beauty coLAB seeding coefficient here: ${value} - (product total*x%)`,
            taskWithPublicationSecondaryRewardNetHint: (value: string) =>
                `Calculated based on below formula: AVE. Value of SoMe Publication Reward in Beauty coLAB (Secondary Reward) - (Product Market Value in 1 Package * Beauty coLAB seeding coefficient here: ${value} - (product total*x%)`,
            numberOfSecondaryRewardsHint: (value: string) => `${value}% of all packages `,
            questionnaireRewardInTotalNetHint: (value: string) => `number of packages x ${value}`,
            quizRewardInTotalNetHint: (value: string) => `number of packages x ${value}`,
        },
    },
    ActivationTask: {
        title: 'Task',
        back: 'Back',
        save: 'Save',
        next: 'Go to next step',
        backToDetails: 'Back to activation details',
        send: 'Accept and send',
        type: 'Task Type',
        displayName: 'Activation Display name',
        price: 'Price',
        start: 'Start Date',
        end: 'End Date',
        stage: 'Etap',
        from: 'z',
        content: 'Task content',
        endMessage: 'Contest End Message',
        sharepoint: {
            label: 'SharePoint Link to any additional materials (foto, wideo etc.)',
            placeholder: 'Fill',
            required: 'This field is required',
        },
        push: {
            label: 'Push',
            placeholder: 'Select',
            required: 'This field is required',
            yes: 'Yes',
            no: 'No',
        },
        pushDate: {
            label: 'Date',
            placeholder: 'Fill',
            required: 'This field is required',
        },
        pushTime: {
            label: 'Time',
            placeholder: 'Fill',
            required: 'This field is required',
        },
        pushContent: {
            label: 'Message Content',
            placeholder:
                'Fill (push content, max 150 characters, possibility to use emoji, no formatting possible ex. bold, cursive)',
            required: 'This field is required',
            max: 'This field cannot contain more than 150 characters',
        },
        copy: {
            required: 'Complete the missing field',
        },
        success: 'Activation template has been successfully saved',
        successVerification: 'Activation template has been sent for verification',
        error: 'Please correct errors in marked fields',
    },
    ActivationTemplate: {
        title: 'Template',
    },
    ActivationTemplateSteps: {
        step: 'Step',
    },
    ActivationTemplateStepOneForm: {
        goal: {
            label: 'Activation Goal',
            placeholder: 'Fill',
            required: 'This field is required',
        },
        displayName: {
            label: 'Activation Display name',
            placeholder: 'Fill',
            required: 'This field is required',
        },
        brand: 'Brand',
        start: 'Start Date',
        end: 'End Date',
        duration: 'Duration',
        days: 'days',
        budget: 'All Activation Rewards in Diamonds',
        seeding: 'Total Seeding Costs with Tax',
        seedingNet: 'Total Seeding Cots net',
        seedingTax: 'Seeding Tax Cost',
        noSeeding: 'Total Taxable Rewards Costs with Tax',
        noSeedingNet: 'Total Taxable Rewards Costs net',
        noSeedingTax: 'Taxable Rewards Tax Cost',
        save: 'Save',
        backToDetails: 'Back to activation details',
        next: 'Go to next step',
        success: 'Activation template has been successfully saved',
        error: 'Please correct errors in marked fields',
    },
    Api: {
        networkError: 'Wystąpił problem z siecią',
        unknownError: 'Wystąpił błąd. Spróbuj ponownie później',
    },
    BrandFieldArray: {
        add: 'Add brand',
        label: 'Brand and brand budget',
        delete: 'Delete',
        brand: {
            label: 'Brand',
            placeholder: 'Select',
            error: 'Please, choose a brand of your activation',
        },
        budgetPercent: {
            label: 'Budget split in',
            placeholder: 'Fill',
        },
        owner: {
            label: 'Activation Owner (e-mail)',
            placeholder: 'Fill',
            error: 'Please, type in an correct email',
        },
    },
    BlogTemplate: {
        allLabel: 'Wszystkie',
    },
    Cart: {
        headingColumn: {
            first: 'Produkty w koszyku',
        },
        disclaimer:
            'Nie zwlekaj z zakupem, dodanie artykułów do koszyka nie oznacza ich rezerwacji.',
        empty: 'Twój koszyk jest pusty.',
        button: 'Wróć do sklepu',
    },
    CartModule: {
        button: 'Koszyk',
    },
    CartSummary: {
        title: 'Podsumowanie zamówienia',
        button: 'Przejdź do kasy',
    },
    CartWidget: {
        empty: 'Twój koszyk jest pusty',
        addProducts:
            'Wygląda na to, że Twój koszyk jest pusty. Dodaj produkty, by dokonać zamówienia.',
        checkout: 'Przejdź do kasy',
        start: 'Zacznij zakupy',
        closeButton: 'Zamknij',
    },
    ChangeBudget: {
        title: 'Change Budget',
        back: 'Back to cost summary',
        save: 'Save',
        history: 'Changes history',
        empty: 'No changes for selected budget',
        budget: {
            current: 'Current budget',
            new: 'New budget',
            placeholder: 'Fill',
            error: {
                required: 'Enter new budget',
                min: (min: number | string) => `The new budget cannot be less than ${min}`,
            },
        },
        error: 'An error occurred and the data for the selected criteria could not be loaded.',
        table: {
            date: 'Date',
            user: 'User',
            previous: 'Previous budget',
            next: 'New budget',
        },
    },
    CopyField: {
        fill: 'Fill',
        ok: 'Ok',
        edit: 'Edit',
        placeholder: 'Please enter your copy',
    },
    Contact: {
        thankYouTitle: 'Dziękujemy, za Twoją wiadomość!',
        thankYouContent:
            'Dziękujemy za skontaktowanie się z nami. Skontaktujemy się z Tobą tak szybko, jak to możliwe.',
    },
    ContactForm: {
        name: {
            label: 'Imię i nazwisko',
            error: {
                required: 'Imię i nazwisko jest wymagane',
            },
        },
        email: {
            label: 'Email',
            error: {
                required: 'Email jest wymagany',
                email: 'Sprawdź poprawność adresu Email',
            },
        },
        phone: {
            label: 'Numer telefonu',
            error: {
                phone: 'Sprawdź poprawność numeru telefonu',
            },
        },
        content: {
            label: 'Wiadomość',
            error: {
                required: 'Wiadomość jest wymagana',
            },
        },
        approval1: {
            error: {
                required: 'Twoja zgoda jest wymagana',
            },
        },
        submit: {
            label: 'Wyślij',
        },
    },
    CostSummary: {
        title: 'Cost Summary',
        search: 'Search activation',
        titleCdmo: 'CDMO budget split by conso %',
        titleBrand: 'Brand budget',
        change: 'Change budget',
        budget: 'BUDGET',
        budgetEstimated: 'WHAT IS LEFT - estimated',
        budgetReal: 'WHAT IS LEFT - real',
        packagesEstimated: 'PACKAGES THAT I CAN SEND - estimated',
        packagesEstimatedHint:
            'Estimated number of packages that you can send with the budget you have left.',
        packagesReal: 'PACKAGES THAT I CAN SEND - real',
        packagesRealHint: 'Real number of packages that you can send with the budget',
        verify: 'verify seeding',
        empty: 'No activation for selected search criteria',
        error: 'An error occurred and the data for the selected criteria could not be loaded.',
        table: {
            activationName: 'Activation name',
            cdmo: {
                estimatedCost: 'Estimated all activation rewards in PLN',
                realCost: 'Real all activation rewards in PLN',
                estimatedTax: 'Estimated all activations rewards tax in PLN',
                realTax: 'Real all activations rewards tax in PLN',
                title: 'CDMO budget split by conso %',
            },
            brand: {
                estimatedCost: 'Estimated logistics cost for all packages in PLN',
                realCost: 'Real logistics cost for all packages in PLN',
                estimatedTax: 'Estimated products tax cost for all packages in PLN',
                realTax: 'Real products tax cost for all packages in PLN',
                title: 'Brand budget',
            },
            dedNumber: 'DED number for logistics cost',
            budgetPercent: 'Budget split in %',
        },
    },
    Dashboard: {
        title: 'Activation Calendar',
    },
    Filter: {
        button: {
            less: 'Mniej',
            more: 'Więcej',
        },
    },
    Filters: {
        filters: 'Filtry',
        showAll: 'Pokaż wszystkie produkty',
        closeFilters: 'Schowaj filtry',
    },
    FilterTag: {
        clear: 'Wyczyść filtr',
    },
    Footer: {
        copyright: 'Wszystkie prawa zastrzeżone ©',
    },
    FooterNavList: {
        openNavigation: 'Rozwiń menu:',
    },
    GiftFinderBanner: {
        title: 'Gift finder',
        text: 'Znajdź idealny prezent',
    },
    Header: {
        buttons: {
            search: 'Szukaj',
            showNavigation: 'Otwórz menu',
            hideNavigation: 'Zamknij menu',
        },
    },
    Input: {
        showPassword: 'Pokaż hasło',
    },
    InputFile: {
        errorRead: (name: string) => `Failed to upload the file ${name}.`,
        maxSize: (maxSize: number, name: string) =>
            `The ${name} file is too large, the maximum file size is ${maxSize}MB.`,
        maxCount: (maxCount: number) => `The maximum number of files is ${maxCount}.`,
        upload: 'Click to add',
        typeNotAccepted: (mimeType: string) => `Illegal file extension: ${mimeType}.`,
    },
    InstagramRegisterForm: {
        firstName: {
            label: 'Imię*',
            placeholder: 'Wprowadź ',
            required: 'Proszę wprowadzić imię',
        },
        lastName: {
            label: 'Nazwisko*',
            placeholder: 'Wprowadź odpowiedź',
            required: 'Proszę wprowadzić nazwisko',
        },
        email: {
            label: 'Adres e-mail*',
            placeholder: 'Wprowadź odpowiedź',
            required: 'Proszę wprowadzić e-mail',
        },
        instagramName: {
            label: 'Nazwa konta na Instagramie*',
            placeholder: 'Wprowadź odpowiedź',
            required: 'Proszę wprowadzić nazwę konta',
            note: 'np.: @haniabeauty',
        },
        tiktokName: {
            label: 'Nazwa konta na TikToku',
            placeholder: 'Wprowadź odpowiedź',
            required: 'Proszę wprowadzić nazwę konta',
            note: 'np.: @haniabeauty',
        },
        consent1: {
            label: (url: string) =>
                `Akceptuję warunki <a class="link" style="text-decoration: none" href='${url}' target="_blank"><span style="font-weight: bold">Regulaminu Weryfikacji</span></a> w tym postanowienia dotyczące ochrony danych osobowych.`,
            required: 'Ta zgoda jest wymagana',
        },
        consent2: {
            label: (url: string) =>
                `Wyrażam zgodę na weryfikację moich danych osobowych, w tym mojego konta i publikowanych przeze mnie treści w serwisie Instagram zgodnie z postanowieniami <a class="link" style="text-decoration: none" href='${url}' target="_blank"><span style="font-weight: bold">Regulaminu Weryfikacji</a> przez L’ORÉAL Polska Sp. z o.o. ul. Grzybowska 62, 00-844 Warszawa w celu możliwości przystąpienia do Platformy Konkursowej Beauty coLab. Informujemy o przysługującym prawie do wycofania zgody w dowolnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Wycofanie zgody możliwe jest za pośrednictwem kontaktu na adres e-mail: <span style="text-decoration: underline">personal-da@loreal.com.</span>`,
            required: 'Ta zgoda jest wymagana',
        },
        followers: {
            label: 'Czy posiadasz minimum 1000 obserwatorów na Instagramie?*',
            required: 'Ta zgoda jest wymagana',
            options: {
                yes: 'Tak',
                no: 'Nie',
            },
        },
        ofAge: {
            label: 'Czy potwierdzasz, że ukończyłeś/aś 18 lat?*',
            required: 'Ta zgoda jest wymagana',
            options: {
                yes: 'Tak',
                no: 'Nie',
            },
        },
        shippingAddress: {
            label: 'Czy posiadasz stały adres do wysyłki paczek w Polsce?*',
            required: 'Ta zgoda jest wymagana',
            options: {
                yes: 'Tak',
                no: 'Nie',
            },
        },
        knowledge: {
            label: 'W jaki sposób otrzymałaś/eś link do formularza rejstracyjnego Beauty coLAB?*',
            required: 'Ta informacja jest wymagana',
            options: {
                label: 'Otrzymałam/em zaproszenie do Beauty coLAB w wiadomości na IG/e-mail od agencji Enyo.',
                label2: 'Dowiedziałam/em się o Beauty coLAB z innego źródła (np. przez znajomych, media społecznościowe etc.) i zgłosiłam/em chęć dołączenia.',
            },
        },
        description: {
            label: 'Dlaczego powinniśmy wybrać Ciebie do społeczności Beauty coLAB?*',
            placeholder: 'Wprowadź odpowiedź',
            required: 'Proszę wprowadzić odpowiedź',
        },
        submit: 'WYSYŁAM ZGŁOSZENIE I POBIERAM REGULAMIN',
    },
    LabelAggregator: {
        optionalLabel: '(opcjonalnie)',
    },
    LinkCard: {
        button: 'Zobacz',
    },
    ListSort: {
        sort: 'Sortuj',
    },
    LoginForm: {
        email: {
            label: 'E-mail address',
            placeholder: 'haniakowalska@gmail.com',
            error: {
                required: 'E-mail is required',
                email: 'Please enter a valid e-mail address.',
            },
        },
        password: {
            label: 'Password',
            placeholder: '***********',
            error: {
                required: 'Password is required',
                min: 'The password should be at least 8 characters long',
                max: 'The password cannot be longer than 32 characters',
            },
        },
        submit: {
            label: 'Login',
        },
    },
    LoginFormWithLink: {
        question: 'Nie pamiętasz hasła?',
        link: 'Odzyskaj hasło',
    },
    Logo: {
        title: 'Strona główna',
    },
    Map: {
        directions: 'Trasa',
        largeMap: 'Powiększ mapę',
    },
    ModalLayout: {
        cancel: 'nie',
        confirm: 'tak',
        close: 'Zamknij modal',
    },
    MobileNavigationList: {
        goTo: 'Przejdź do:',
    },
    Newsletter: {
        error: {
            network: 'Wystąpił problem z siecią',
            global: 'Wystąpił błąd. Spróbuj ponownie później',
        },
        success: {
            title: 'Dziękujemy, że jesteś z nami!',
            subtitle: 'Zapis do newslettera przebiegł pomyślnie',
        },
    },
    NewsletterConsentForm: {
        submit: 'Zapisz',
    },
    NewsletterForm: {
        email: {
            placeholder: 'Wprowadź swój e-mail',
            label: 'Email',
            error: 'Ups... wystąpił błąd. Proszę sprawdź, czy podany e-mail zawiera "@"',
            required: 'E-mail jest wymagany',
        },
        consent: 'Twoja zgoda jest wymagana',
    },
    Order: {
        stepTitle: {
            address: '1. Wysyłka',
            deliveryProvider: '2. Dostawa',
            paymentProvider: '3. Płatność',
        },
        consentError: 'Wymagane jest zaakceptowanie wszystkich zgód',
        pay: 'Płacę',
    },
    OrderAddressFields: {
        box: {
            buyer: {
                title: 'Kupujesz jako',
            },
            companyInvoice: {
                title: 'Dane firmowe do faktury',
            },
            address: {
                title: 'Adres dostawy',
            },
            invoice: {
                title: 'Dane do faktury',
                info: 'W naszym sklepie internetowym dowodem zakupu jest faktura. Standardowo wystawiamy ją na dane z adresu dostawy.',
            },
        },
        fields: {
            wantOtherInvoiceData: {
                label: 'Chcę podać inne dane do faktury',
            },
            buyerType: {
                private: {
                    label: 'Osoba prywatna',
                },
                company: {
                    label: 'Firma',
                },
            },
            firstName: {
                label: 'Imię*',
                error: {
                    required: 'Imię jest wymagane',
                },
            },
            lastName: {
                label: 'Nazwisko*',
                error: {
                    required: 'Nazwisko jest wymagane',
                },
            },
            street: {
                label: 'Ulica*',
                error: {
                    required: 'Ulica jest wymagana',
                },
            },
            houseNumber: {
                label: 'Nr domu*',
                error: {
                    required: 'Nr domu jest wymagany',
                },
            },
            apartmentNumber: {
                label: 'Nr mieszkania',
            },
            zipCode: {
                label: 'Kod pocztowy*',
                error: {
                    required: 'Kod pocztowy jest wymagany',
                },
            },
            city: {
                label: 'Miasto*',
                error: {
                    required: 'Miasto jest wymagane',
                },
            },
            phoneNumber: {
                label: 'Numer telefonu*',
                error: {
                    required: 'Numer telefonu jest wymagany',
                },
            },
            email: {
                label: 'Email*',
                error: {
                    required: 'Adres email jest wymagany',
                    email: 'Wprowadź poprawny adres Email',
                },
            },
            taxNumber: {
                label: 'NIP*',
                error: {
                    required: 'NIP jest wymagany',
                },
            },
            companyName: {
                label: 'Nazwa firmy*',
                error: {
                    required: 'Nazwa firmy jest wymagana',
                },
            },
        },
    },
    OrderConsentForm: {
        firstConsent: 'Twoja zgoda jest wymagana',
        secondConsent: 'Twoja zgoda jest wymagana',
    },
    OrderData: {
        edit: 'Edytuj',
    },
    OrderDeliveryFields: {
        box: {
            title: 'Wybierz formę dostawy',
        },
        fields: {
            providerId: {
                error: {
                    required: 'Wybierz formę dostawy',
                },
            },
            additional: {
                error: {
                    required: 'To pole jest wymagane',
                },
            },
        },
    },
    OrderDetails: {
        item: {
            value: 'Wartość produktów',
            discount: 'Rabat',
            shipment: 'Wysyłka',
            finalPrice: 'Do zapłaty (w tym VAT)',
        },
        deliveryPrice: {
            from: 'Od',
            free: 'Gratis',
        },
    },
    OrderPaymentFields: {
        box: {
            title: 'Jak chcesz zapłacić?',
        },
        fields: {
            providerId: {
                error: {
                    required: 'Wybierz formę płatności',
                },
            },
        },
    },
    OrderDiscountCodeForm: {
        discountCode: {
            placeholder: 'Wpisz kod rabatowy',
        },
        apply: 'Zastosuj',
        delete: 'Usuń',
        header: 'Aktywne kody:',
    },
    OrderLogin: {
        goBack: 'Wróć do koszyka',
        login: {
            title: 'Witaj ponownie',
            content: 'Cieszymy się, że jesteś z nami. Zaloguj się i zamów.',
            disclaimer: 'Nie pamiętasz hasła?',
            link: 'Odzyskaj hasło',
        },
        register: {
            title: 'Nie mam jeszcze konta',
            content:
                'Zarejestruj się i zyskaj - szybsze składanie zamówień, wgląd do obecnych i poprzednich zamówień i wiele więcej.',
            link: 'Załóż konto',
        },
        guest: {
            title: 'Chcę kupić jako gość',
            content: 'Nie ma problemu, poprosimy tylko o dane niezbędne do realizacji zamówienia.',
            link: 'Przejdź do kasy',
        },
    },
    OrderLoginForm: {
        email: {
            label: 'Email*',
            error: {
                required: 'Email jest obowiązkowy',
                email: 'Wprowadź poprawny adres Email',
            },
        },
        password: {
            label: 'Hasło*',
            error: {
                required: 'Hasło jest obowiązkowe',
                min: 'Hasło powinno mieć minimum 8 znaków',
                max: 'Hasło nie może być dłuższe niż 32 znaki',
            },
        },
        submit: {
            label: 'Przejdź do kasy',
        },
    },
    OrderHistoryTable: {
        header: [
            'Numer zamówienia',
            'Data złożenia zamówienia',
            'Liczba produktów',
            'Wartość zamówienia',
            'Forma płatności',
            'Sposób dostawy',
            'Status zamówienia',
            'Szczegóły zamówienia',
        ],
        details: 'Szczegóły',
        retry: 'Ponów płatność',
        formLink: 'Wypełnij ankiete',
    },
    OrderHistoryList: {
        orderId: 'Numer zamówienia',
        date: 'Data złożenia zamówienia',
        numberOfItems: 'Liczba produktów',
        finalPrice: 'Wartość zamówienia',
        details: 'Szczegóły',
        retry: 'Ponów płatność',
        form: 'Ankieta',
        formLink: 'Wypełnij ankiete',
    },
    OrderNoteForm: {
        userNote: {
            label: 'Uwagi do zamówienia',
            placeholder: 'Wpisz treść',
        },
    },
    OrderStep: {
        edit: 'Edytuj',
        next: 'Dalej',
        save: 'Zapisz',
        cancel: 'Anuluj',
        error: {
            selectedProviderUnavailable:
                'Zmieniono zamówienie, wybrany wcześniej sposób dostawy jest niedostępny, wybierz ponownie.',
        },
    },
    OrderSummary: {
        title: 'Podsumowanie zamówienia',
    },
    PanelSummary: {
        button: 'Plan an activation',
        empty: 'No scheduled activations',
        seeding: 'Seeding',
        noSeeding: 'No seeding',
    },
    PasswordChange: {
        success: 'Zmiany zapisane poprawnie.',
    },
    PasswordChangeForm: {
        currentPassword: {
            label: 'Aktualne hasło*',
            error: {
                min: 'Hasło musi mieć minimum 8 znaków',
                max: 'Hasło nie może mieć więcej niż 32 znaki',
                required: 'Podanie aktualnego hasła jest wymagane',
            },
        },
        newPassword: {
            label: 'Nowe hasło*',
            error: {
                min: 'Hasło musi mieć minimum 8 znaków',
                max: 'Hasło nie może mieć więcej niż 32 znaki',
                required: 'Podanie nowego hasła jest wymagane',
            },
        },
        repeatPassword: {
            label: 'Potwierdź hasło*',
            error: {
                min: 'Hasło musi mieć minimum 8 znaków',
                max: 'Hasło nie może mieć więcej niż 32 znaki',
                mustMatch: 'Hasła muszą być identyczne',
                required: 'Musisz potwierdzić hasło przed przesłaniem formularza',
            },
        },
        submit: {
            label: 'Zapisz',
        },
    },
    PasswordRecovery: {
        email: {
            label: '',
            error: {
                required: 'Podaj adres email',
                email: 'Podaj prawidłowy adres email',
            },
            placeholder: 'Wpisz swój adres email',
        },
        submit: {
            label: 'Wyślij',
        },
    },
    PasswordResetForm: {
        newPassword: {
            label: 'Nowe hasło*',
            error: {
                min: 'Hasło musi mieć minimum 8 znaków',
                max: 'Hasło nie może mieć więcej niż 32 znaki',
                required: 'Podanie nowego hasła jest wymagane',
            },
        },
        repeatPassword: {
            label: 'Potwierdź hasło*',
            error: {
                min: 'Hasło musi mieć minimum 8 znaków',
                max: 'Hasło nie może mieć więcej niż 32 znaki',
                mustMatch: 'Hasła muszą być identyczne',
                required: 'Musisz potwierdzić hasło przed przesłaniem formularza',
            },
        },
        token: {
            error: 'Zły token uwierzytelniający.',
        },
        submit: {
            label: 'Zapisz',
        },
    },
    PostCard: {
        readmore: 'Czytaj dalej',
    },
    PostHeader: {
        searchPlaceholder: 'Szukaj...',
    },
    PostListing: {
        error: 'Wystąpił błąd podczas pobierania artykułów. Spróbuj ponownie później...',
        empty: 'Nie znaleziono żadnych artykułów.',
    },
    PriceTag: {
        noPrice: 'Brak ceny',
    },
    ProductListing: {
        error: 'Wystąpił błąd podczas pobierania produktów. Spróbuj ponownie później...',
        quantity: 'Ilość produktów:',
        notFound: 'Nie znaleziono żadnych produktów.',
    },
    ProductAttributes: {
        title: 'Specyfikacja produktu',
    },
    ProductCard: {
        new: 'New',
        bestseller: 'Bestseller',
        unavailable: 'Produkt niedostępny',
        addToCard: 'Dodaj do koszyka',
        goTo: 'Przejdź do produktu:',
    },
    ProductDetails: {
        priceFetchingError: 'Podczas pobierania cen wystąpił błąd',
        inStock: 'Dostępny w magazynie',
        outOfStock: 'Brak w magazynie',
        addToCart: 'Dodaj do koszyka',
        gross: 'brutto',
        net: 'netto',
    },
    ProductSlider: {
        slidePrev: 'Poprzedni slajd',
        slideNext: 'Następny slajd',
    },
    RadioGroup: {
        from: 'od',
        to: 'do',
        check: 'Zaznacz',
        valueFrom: 'Wartość od',
        valueTo: 'Wartość do',
        errors: {
            max: 'Wartość minimalna nie może być większa od maksymalnej.',
            value: 'Wartość',
            lower: 'nie może być mniejsza niż',
            higher: 'nie może być większa niż',
        },
    },
    RegisterModal: {
        confirm: 'Okej, rozumiem',
        title: 'Jak dołączyć do <span class="colored">Beauty coLAB</span>?',
        item1: '<b>1.</b> Przeczytaj cały Regulamin.',
        item2: '<b>2.</b> Po dojściu do końca Regulaminu, zaznacz i zaakceptuj wymagane zgody.',
        item3: '<b>3.</b> Uzupełnij pozostałe dane.',
        item4: '<b>4.</b> Pamiętaj, że aby otrzymać dostęp do Beauty coLAB musisz mieć minimum 1000 obserwatorów na Instagramie, mieć skończone 18 lat i posiadać stały adres do wysyłki paczek w Polsce.',
        item5: '<b>5.</b> Kliknij w przycisk i wyślij swoje zgłoszenie do Beauty coLAB!',
    },
    RegistrationForm: {
        firstName: {
            label: 'Imię',
            error: {
                required: 'Imię jest obowiązkowe',
            },
        },
        lastName: {
            label: 'Nazwisko',
            error: {
                required: 'Nazwisko jest obowiązkowe',
            },
        },
        email: {
            label: 'Email',
            error: {
                required: 'Email jest obowiązkowy',
                email: 'Wprowadź poprawny adres Email',
            },
        },
        password: {
            label: 'Hasło',
            error: {
                required: 'Hasło jest obowiązkowe',
                min: 'Hasło powinno mieć minimum 8 znaków',
                max: 'Hasło nie może być dłuższe niż 32 znaki',
            },
        },
        consent: {
            error: {
                required: 'Twoja zgoda jest wymagana',
            },
        },
        submit: {
            label: 'Utwórz konto',
        },
    },
    RTKQueryApi: {
        errors: {
            FETCH_ERROR: 'Wystąpił problem podczas wysyłania żądania',
            PARSING_ERROR: 'Wystąpił problem podczas przetwarzania odpowiedzi żądania',
            TIMEOUT_ERROR: 'Czas na zapytanie został przekroczony',
            CUSTOM_ERROR: 'Wystąpił nieznany błąd',
        },
    },
    SearchBar: {
        noResultsFirstPart: 'Brak wyników wyszukiwania dla „',
        noResultsSecondPart: '”. Wypróbuj inne słowa kluczowe.',
        error: 'Wystąpił problem podczas wyszukiwania.',
        inputPlaceholder: 'Szukaj...',
        closeButton: 'Schowaj wyszukiwarkę',
    },
    SearchDropdown: {
        categoryTitle: 'Kategorie',
        productsTitle: 'Produkty',
        seeAllProducts: 'Zobacz wszystkie produkty',
    },
    SearchInput: {
        name: 'Search',
    },
    Select: {
        defaultPlaceholder: 'Select...',
        defaultNoOptions: 'No options',
    },
    SelectedFilters: {
        clear: 'Wyczyść wszystko',
    },
    Shop: {
        results: 'Wyniki wyszukiwania dla',
    },
    SignUp: {
        title: 'Cieszymy się, że chcesz dołączyć do <span class="colored">Beauty coLAB!</span>',
        subtitle: 'REJESTRACJA',
        description:
            "Dołącz do unikalnej aplikacji stworzonej przez grupę L'Oréal specjalnie dla influencerów. Aby zawnioskować o dołączenie, jest nam potrzebnych kilka informacji o Tobie.",
        descriptionBottom:
            'Przeczytaj Regulamin, zaakceptuj wymagane zgody, a następnie uzupełnij swoje dane – zajmie to tylko chwilę!',
        annotation: 'Dziękujemy za wybór <span class="colored">Beauty coLAB</span>!',
        error: {
            network: 'Wystąpił problem z siecią',
            global: 'Wystąpił błąd. Spróbuj ponownie później',
            rules: 'Nie udało się pobrać regulaminu. Przepraszamy.',
        },
        rules: 'Regulamin',
        companyName: 'Beauty coLAB',
        previousRules: 'Poprzednie Regulaminy',
        footer: 'Po uzupełnieniu formularza, wyślij swoje zgłoszenie. Twoje odpowiedzi zostaną sprawdzone w ciągu <span class="bold">5 dni roboczych.</span> O wynikach weryfikacji poinformujemy Cię w DM na Instagramie lub w wiadomości na adres e-mail podany w formularzu. Aby zapewnić Ci stały dostęp do Regulaminu Beauty coLAB i zagwarantować transparentność, po wysłaniu formularza dokument zostanie automatycznie pobrany na Twoje urządzenie.',
    },
    SignIn: {
        email: 'E-mail address',
        password: 'Password',
    },
    SignInWidget: {
        links: {
            login: 'Zaloguj się',
            register: 'Nowy klient',
        },
    },
    SummaryDisclaimer: {
        providerInfo: '100% bezpieczne zakupy',
    },
    Survey: {
        button: 'Wyślij odpowiedzi',
        redirect: 'Wróć na strone główną',
        uuid: {
            error: 'Nie prawidłowy numer zamówienia',
        },
    },
    Toast: {
        closeButton: 'Zamknij',
    },
    ThankYouTemplate: {
        title: 'Thank You!',
        content:
            'You activation copy has been sent to the Beauty coLAB team for verification. If there are any changes needed, you will be notified. Please remember to check your activation status.',
        button: 'Go to activation details',
    },
    UpwardsButton: {
        up: 'Do góry',
    },
    UseBudget: {
        brand: 'Brand',
        year: 'Year',
        label: {
            cdmo: 'CDMO budget split by conso %',
            brand: 'Brand budget',
        },
    },
    UserAddress: {
        edit: 'Edytuj',
        default: 'Ustaw jako domyślny',
    },
    UserAddresses: {
        title: 'Adresy płatnika',
        new: 'Dodaj nowy',
        delivery: 'Adresy dostawy',
    },
    UseLanguageConfig: {
        pl: 'Polski',
        en: 'Angielski',
    },
    UserAgreementForm: {},
    UserData: {
        success: 'Zmiany zapisane poprawnie.',
    },
    UserDataForm: {
        firstName: {
            label: 'Imię*',
            error: {
                required: 'Imię jest wymagane',
            },
        },
        lastName: {
            label: 'Nazwisko*',
            error: {
                required: 'Nazwisko jest wymagane',
            },
        },
        email: {
            label: 'Email*',
            error: {
                email: 'Wprowadź poprawny adres email',
                required: 'Email jest wymagany',
            },
        },
        phone: {
            label: 'Numer Telefonu',
            error: {
                phone: 'Sprawdź poprawność numeru telefonu',
            },
        },
        agreement: {
            error: {
                required: 'Zgoda jest wymagana',
            },
        },
        save: {
            label: 'Zapisz',
        },
    },
    UserHeader: {
        title: 'Moje konto',
        myData: 'Moje dane',
        order: 'Zamówienie',
        addresses: 'Moje adresy',
        password: 'Zmień hasło',
    },
    UserLayout: {
        header: 'Witaj',
    },
    UserModule: {
        showMyAccount: 'Pokaż moje konto',
        hideMyAccount: 'Schowaj moje konto',
    },
    UserNavigation: {
        logout: 'Wyloguj',
    },
    UserNewsletter: {
        success: 'Zmiany zapisane poprawnie.',
    },
    UserOrderDetails: {
        pay: 'Dokończ płatność',
        status: 'Status:',
        paymentStatus: 'Status płatności',
        orderDate: 'Data złożenia zamówienia:',
        paymentMethod: 'Forma płatności:',
        deliveryMethod: 'Sposób dostawy:',
        invoiceAddress: 'Dane firmowe do faktury:',
        deliveryAddress: 'Adres dostawy:',
        orderedProducts: 'ZAMÓWIONE PRODUKTY',
        orderComments: 'UWAGI DO ZAMÓWIENIA',
        orderSummary: 'PODSUMOWANIE ZAMÓWIENIA',
        returned: 'Zwrócone',
        noComments: 'Brak',
    },
    UserOrderHeader: {
        return: 'Wróć do zamówień',
        invoiceDownload: 'Pobierz fakturę',
        projectDownload: 'Pobierz projekt',
    },
} as const;

export default pl;
