import React from 'react';
import { ErrorMessage } from 'formik';

import { container } from './error.module.scss';

export type TErrorElement = 'container';

export interface IErrorProps {
    className?: string;
    classes?: Partial<Record<TErrorElement, string>>;
    name: string;
}

const Error: React.FC<IErrorProps> = ({ className = '', classes, name }) => {
    return (
        <ErrorMessage
            className={`${className} ${container} ${classes?.container || ''} error-message`}
            name={name}
            component="p"
        />
    );
};

export default Error;
