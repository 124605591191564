import alterpressApi from './base';
import {
    IActivation,
    IActivationMutation,
    IActivationPrices,
    IActivationTaskData,
    IOtherActivations,
} from '../../models/activation.model';
import { IActivationSeedingVerificationValues } from '../../formik/activation-seeding-verification.form';

export const activationApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['ACTIVATION'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getActivation: builder.query<IActivation, string | number>({
                query: (id) => `/activations/${id}?expand=media,prices,verifiedPrices`,
                providesTags: ['ACTIVATION'],
            }),
            saveActivation: builder.mutation<IActivation, IActivationMutation>({
                query: (data) => ({
                    method: 'POST',
                    url: `/activations`,
                    body: data,
                }),
            }),
            updateActivation: builder.mutation<IActivation, { id: string | number }>({
                query: ({ id, ...data }) => ({
                    method: 'PATCH',
                    url: `/activations/${id}`,
                    body: data,
                }),
            }),
            deleteActivation: builder.mutation<undefined, string | number>({
                query: (id) => ({
                    method: 'DELETE',
                    url: `/activations/${id}`,
                }),
            }),
            calculateActivation: builder.mutation<IActivationPrices, IActivationMutation>({
                query: (data) => ({
                    method: 'POST',
                    url: `/activations/calculate`,
                    body: data,
                }),
            }),
            calculateVerifiedActivation: builder.mutation<
                IActivationPrices,
                { verifiedPackagesCount: number; activationId: number }
            >({
                query: ({ activationId, verifiedPackagesCount }) => ({
                    method: 'POST',
                    url: `/activations/${activationId}/calculate-verified`,
                    body: { verifiedPackagesCount },
                }),
            }),
            verifyActivation: builder.mutation<
                void,
                { values: IActivationSeedingVerificationValues; activationId: number }
            >({
                query: ({ activationId, values }) => ({
                    method: 'POST',
                    url: `/activations/${activationId}/verify`,
                    body: values,
                }),
                invalidatesTags: ['ACTIVATION'],
            }),
            otherActivations: builder.mutation<
                IOtherActivations[],
                { tasks: IActivationTaskData[] }
            >({
                query: (data) => ({
                    method: 'POST',
                    url: `/activations/check-periods`,
                    body: data,
                }),
            }),
        }),
    });

export const {
    useGetActivationQuery,
    useSaveActivationMutation,
    useUpdateActivationMutation,
    useDeleteActivationMutation,
    useCalculateActivationMutation,
    useOtherActivationsMutation,
    useCalculateVerifiedActivationMutation,
    useVerifyActivationMutation,
} = activationApi;
