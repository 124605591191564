import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

import { IUseMediaConfig } from './use-media';

export interface IMediaImage {
    image?: IGatsbyImageData;
    alt: string;
    url: string;
    caption: string;
}

export function getMediaImage({
    media = [],
    relation,
    fallback,
    useFallback,
}: IUseMediaConfig): IMediaImage {
    const mediaItem = getMediaByRelation({ media, relation, useFallback, fallback });
    return {
        image: mediaItem?.localFile && getImage(mediaItem.localFile),
        alt: mediaItem?.alt || '',
        url: mediaItem?.url || '',
        caption: mediaItem?.caption || '',
    };
}

export function getMediaByRelation({
    media = [],
    relation = 'main-image',
    useFallback = true,
    fallback = 'main-image',
}: IUseMediaConfig): IMedia | undefined {
    let mediaItem = searchMediaByRelation('find', media, relation);

    if (!mediaItem && useFallback) {
        mediaItem = searchMediaByRelation('find', media, fallback);
    }

    return mediaItem;
}

export function getAllMediaByRelation(media: IMedia[], relation: string) {
    return searchMediaByRelation('filter', media, relation).sort((a, b) => {
        const mediaRelationA = a.relations.find(
            (mediaRelation) => mediaRelation.relation === relation
        );
        const mediaRelationB = b.relations.find(
            (mediaRelation) => mediaRelation.relation === relation
        );
        return Number(mediaRelationA?.sequence) - Number(mediaRelationB?.sequence);
    });
}

function searchMediaByRelation(method: 'filter', media: IMedia[], relation: string): IMedia[];
function searchMediaByRelation(
    method: 'find',
    media: IMedia[],
    relation: string
): IMedia | undefined;
function searchMediaByRelation(method: 'filter' | 'find', media: IMedia[], relation: string) {
    return media[method]((item) =>
        item.relations.find((mediaRelation) => mediaRelation.relation === relation)
    );
}
