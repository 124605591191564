import React from 'react';
import { Link } from 'gatsby';

import {
    button,
    normal,
    postCard,
    linkCard,
    productCard,
    disabled,
    hidePointer,
    loading,
    loadingIcon,
    text,
    mainStyle,
    secondaryStyle,
    ctaStyle,
    outline,
} from './button.module.scss';

import LoadingIcon from '../../assets/images/svg/loading.svg';
import InstagramIcon from '../../assets/images/svg/instagram.svg';

interface IButtonElementProps extends React.ComponentProps<'button'> {
    as?: 'button';
    href?: never;
    to?: never;
}

interface IAnchorElementProps extends React.ComponentProps<'a'> {
    as: 'externalLink';
    href: string;
    to?: never;
}

interface ILinkComponentProps extends React.ComponentProps<typeof Link> {
    as: 'link';
    href?: never;
    to: string;
}

interface IDivElementProps extends React.ComponentProps<'div'> {
    as: 'element';
    href?: never;
    to?: never;
}

export type TButtonContext = 'postCard' | 'linkCard' | 'productCard';
export type TStylePresent = 'main' | 'secondary' | 'cta' | 'instagram-cta' | 'outline';

export type TButtonProps = {
    className?: string;
    isDisabled?: boolean;
    context?: TButtonContext;
    showPointer?: boolean;
    isLoading?: boolean;
    stylePreset?: TStylePresent;
    isSignup?: boolean;
} & (IButtonElementProps | IAnchorElementProps | ILinkComponentProps | IDivElementProps);

const Button: React.FC<TButtonProps> = ({
    className = '',
    as = 'button',
    isDisabled = false,
    context,
    children,
    showPointer = false,
    isLoading = false,
    stylePreset,
    isSignup,
    ...rest
}) => {
    const Tag = getTag(as);
    const classNames = generateButtonClassName({
        className,
        isDisabled,
        context,
        showPointer,
        isLoading,
        stylePreset,
    });

    return (
        <Tag
            className={classNames}
            disabled={as === 'button' && (isDisabled || isLoading)}
            {...rest}
        >
            {isLoading && <LoadingIcon className={loadingIcon} />}
            {stylePreset === 'instagram-cta' && <InstagramIcon />}
            <span className={text}>{children}</span>
        </Tag>
    );
};

function getContextClass(context: TButtonProps['context']) {
    if (context === 'postCard') return postCard;
    if (context === 'linkCard') return linkCard;
    if (context === 'productCard') return productCard;
    return normal;
}

function getStylePresetClass(context: TButtonProps['stylePreset']) {
    if (context === 'main') return mainStyle;
    if (context === 'secondary') return secondaryStyle;
    if (context === 'outline') return outline;
    if (context === 'cta' || context === 'instagram-cta') return ctaStyle;
    return '';
}

function getIsDisabledClass(isDisabled: TButtonProps['isDisabled']) {
    return isDisabled ? disabled : '';
}

function getShowPointerClass(showPointer: TButtonProps['showPointer']) {
    return showPointer ? '' : hidePointer;
}

function getIsLoadingClass(isLoading: TButtonProps['isLoading']) {
    return isLoading ? loading : '';
}

function getTag(as: TButtonProps['as']): React.ElementType {
    if (as === 'externalLink') return 'a';
    if (as === 'link') return Link;
    if (as === 'element') return 'div';
    return 'button';
}

type GenerateButtonClassNameProps = Omit<TButtonProps, 'children'>;

function generateButtonClassName(props: GenerateButtonClassNameProps) {
    const { className, context, isDisabled, showPointer, isLoading, stylePreset } = props;

    const contextClass = getContextClass(context);
    const isDisabledClass = getIsDisabledClass(isDisabled);
    const showPointerClass = getShowPointerClass(showPointer);
    const isLoadingClass = getIsLoadingClass(isLoading);
    const stylePresetClass = getStylePresetClass(stylePreset);

    return `${button}\
        ${contextClass}\
        ${isDisabledClass}\
        ${showPointerClass}\
        ${isLoadingClass}\
        ${stylePresetClass}\
        ${className ? className : ''}
        button`;
}

export default Button;
