import React, { useEffect } from 'react';
import ReactSelect, {
    ActionMeta,
    OnChangeValue,
    OptionProps,
    components,
    MultiValue,
    SingleValueProps,
} from 'react-select';
import { useField } from 'formik';
import { BaseImage, IBaseImageProps } from '@alterpage/gatsby-plugin-image';

import {
    container,
    select,
    required,
    disabled,
    selectBox,
    hasIcon,
    iconSvg,
    iconBox,
    imageOptions,
    error,
    errorOutput,
    ratio,
} from './select.module.scss';

import { IError } from '../../utils/get-form-errors';
import { IOption } from '../../models/option.model';
import { TRequiredStarPlacement } from '../../models/form-field.model';
import { getSelectValue } from '../../utils/get-select-value';
import useTranslations from '../../hooks/use-translations';

import Error from './error';
import InputLabel from './input-label';

interface ISelectProps {
    className?: string;
    label?: string;
    name: string;
    options: IOption[];
    isMulti?: boolean;
    placeholder?: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    icon?: React.SVGFactory;
    closeMenuOnSelect?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    requiredPlacement?: TRequiredStarPlacement[];
    hideSelectedOptions?: boolean;
    submitErrors?: IError[];
    handleSubmitErrors?: (errors: IError[]) => void;
    showError?: boolean;
    hint?: string;
    imageWithLabel?: boolean;
    baseImageProps?: Pick<
        IBaseImageProps,
        'objectPosition' | 'objectFit' | 'ratioClass' | 'aspectRatio'
    >;
}

interface ExtendedOptionsProps extends OptionProps<IOption> {
    imageWithLabel: boolean;
    baseImageProps?: Pick<
        IBaseImageProps,
        'objectPosition' | 'objectFit' | 'ratioClass' | 'aspectRatio'
    >;
}

interface ExtendedSingleValueProps extends SingleValueProps<IOption> {
    imageWithLabel: boolean;
    baseImageProps?: Pick<
        IBaseImageProps,
        'objectPosition' | 'objectFit' | 'ratioClass' | 'aspectRatio'
    >;
}

const Select: React.FC<ISelectProps> = ({
    className = '',
    name,
    options,
    hint,
    label,
    placeholder,
    icon,
    closeMenuOnSelect,
    isMulti = false,
    isSearchable = false,
    isClearable = false,
    isRequired = false,
    isDisabled = false,
    hideSelectedOptions = false,
    submitErrors,
    handleSubmitErrors,
    showError = true,
    imageWithLabel = true,
    baseImageProps,
}) => {
    const [, meta, helpers] = useField(name);
    const t = useTranslations('Select');
    const submitError =
        submitErrors && submitErrors.length > 0 && submitErrors.find((e) => e.field === name);
    const hasError =
        !!(meta.error && meta.touched) ||
        (!!submitError && !!meta.value && meta.value.length === 0);
    const Icon = icon;

    const localPlaceholder = placeholder || t.defaultPlaceholder;
    const hasImageOptions = options.find((option) => option.imgUrl);

    const handleChange = (
        option: OnChangeValue<IOption, typeof isMulti> | null,
        actionMeta: ActionMeta<IOption>
    ) => {
        const { action } = actionMeta;
        if (action === 'clear') {
            const newValue = isMulti ? [] : '';
            helpers.setValue(newValue);
            return;
        }
        if (action === 'select-option' && option) {
            const newValue = assertIsMultiValue(option)
                ? option.map(({ value }) => value)
                : option.value;
            helpers.setValue(newValue);
            return;
        }
        if (
            (action === 'remove-value' || action === 'deselect-option') &&
            assertIsMultiValue(option)
        ) {
            const newValue = option.map(({ value }) => value);
            helpers.setValue(newValue);
            return;
        }
    };

    useEffect(() => {
        if (submitErrors && submitError && handleSubmitErrors && meta.value && meta.touched) {
            handleSubmitErrors(submitErrors.filter((e) => e !== submitError));
        }
    }, [meta.value]);

    return (
        <div
            className={`
            ${container} 
            ${className} 
            ${!label && isRequired ? required : ''}
            ${isDisabled ? disabled + ' disabled' : ''}
            ${hasError ? error : ''}`}
            data-formik-name={name}
        >
            {label && (
                <InputLabel
                    hint={hint}
                    isDisabled={isDisabled}
                    isRequired={isRequired}
                    isError={hasError}
                >
                    {label}
                </InputLabel>
            )}
            <div className={`${selectBox} ${icon ? hasIcon : ''} select__container`}>
                {Icon && (
                    <div className={`${iconBox} select__icon-box`}>
                        <Icon className={`${iconSvg} select__icon`} />
                    </div>
                )}
                <ReactSelect
                    className={`${select} ${hasImageOptions ? imageOptions : ''} select__select`}
                    options={options.map((option) => ({ ...option, isDisabled: !option.enabled }))}
                    classNamePrefix="react-select"
                    onChange={handleChange}
                    value={getSelectValue(meta.value, options, isMulti)}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    components={{
                        Option: (props) => (
                            <CustomOption
                                imageWithLabel={imageWithLabel}
                                baseImageProps={baseImageProps}
                                {...props}
                            >
                                {props.children}
                            </CustomOption>
                        ),
                        SingleValue: (props) => (
                            <CustomSingleValue
                                imageWithLabel={imageWithLabel}
                                baseImageProps={baseImageProps}
                                {...props}
                            >
                                {props.children}
                            </CustomSingleValue>
                        ),
                    }}
                    placeholder={localPlaceholder}
                    hideSelectedOptions={hideSelectedOptions}
                    isSearchable={isSearchable}
                    closeMenuOnSelect={closeMenuOnSelect}
                    noOptionsMessage={() => t.defaultNoOptions}
                />
            </div>
            {showError && <Error name={name} />}
            {hasError && submitError && <p className={errorOutput}>{submitError.content}</p>}
        </div>
    );
};

const CustomOption = (props: ExtendedOptionsProps) => {
    const {
        label,
        selectProps,
        data: { imgUrl },
        imageWithLabel,
        baseImageProps,
    } = props;
    const { isMulti } = selectProps;

    return (
        <components.Option {...props}>
            {imgUrl ? (
                <>
                    <BaseImage
                        className="react-select__option-image"
                        image={undefined}
                        url={imgUrl}
                        alt={label}
                        objectFit="contain"
                        {...baseImageProps}
                    />
                    {imageWithLabel && <span>{label}</span>}
                </>
            ) : isMulti ? (
                <div>
                    <span>{label}</span>
                </div>
            ) : (
                label
            )}
        </components.Option>
    );
};

const CustomSingleValue = (props: ExtendedSingleValueProps) => {
    const {
        data: { imgUrl, label },
        imageWithLabel,
        baseImageProps,
    } = props;
    return (
        <components.SingleValue {...props}>
            {imgUrl ? (
                <>
                    <BaseImage
                        className="react-select__value-image"
                        image={undefined}
                        url={imgUrl}
                        alt={label || ''}
                        objectFit="contain"
                        ratioClass={ratio}
                        {...baseImageProps}
                    />
                    {imageWithLabel && <span>{label}</span>}
                </>
            ) : (
                label
            )}
        </components.SingleValue>
    );
};

function assertIsMultiValue(value: unknown): value is MultiValue<IOption> {
    return Array.isArray(value);
}

export default Select;
