import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import * as locale from '../locale';
import { TLocale } from '../locale';

export default function useTranslations<K extends keyof TLocale>(key: K) {
    const { locale: language } = usePageContext();
    const currentLocale = locale[language as keyof typeof locale] ?? locale['pl'];

    if (!currentLocale) {
        throw Error(`Cannot found language: ${language} in /src/locale directory.`);
    }

    if (!currentLocale[key]) {
        throw Error(`Cannot found translations for language: ${language} and key: ${key}.`);
    }

    return currentLocale[key];
}
