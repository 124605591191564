import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import { markdown, defaultStyles, imageHolder } from './markdown.module.scss';

import { dataLayerEvents } from '../../config/data-layer';
import createImageHolder from '../../utils/create-image-holder';

import MarkdownLink from '../atoms/markdown-link';
import MarkdownScript from '../atoms/markdown-script';

interface IMarkdownProps extends ReactMarkdownOptions {
    children: string;
    components?: ReactMarkdownOptions['components'];
    className?: string;
    useDefaultStyles?: boolean;
}

const Markdown: React.FC<IMarkdownProps> = ({
    children,
    className = '',
    components,
    useDefaultStyles = false,
    ...rest
}) => {
    const handleClick = (href: string) => {
        dataLayerEvents.socialClick('Instagram', 'send registration', `${href}`);
    };

    return (
        <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
                h1: 'h2',
                a: ({ href, children, target, className, style }) => (
                    <span
                        onClick={() => {
                            if (href && href.includes('instagram')) {
                                handleClick(href);
                            }
                        }}
                    >
                        <MarkdownLink
                            href={href}
                            target={target}
                            className={className}
                            style={style}
                        >
                            {children}
                        </MarkdownLink>
                    </span>
                ),
                script: ({ node, ...props }) => <MarkdownScript {...props} />,
                p: ({ children, node, className, style }) => {
                    return createImageHolder({
                        children,
                        nodeChildren: node.children[0],
                        Tag: node.tagName,
                        commonClassName: className,
                        className: imageHolder,
                        style: style,
                    });
                },
                figure: ({ children, node, className, style }) => {
                    if (
                        node.properties &&
                        Array.isArray(node.properties.className) &&
                        node.properties.className.includes('table')
                    ) {
                        return (
                            <table className={className} style={style}>
                                {children}
                            </table>
                        );
                    }
                    return createImageHolder({
                        children,
                        nodeChildren: node.children[0],
                        Tag: node.tagName,
                        commonClassName: className,
                        className: imageHolder,
                        style: style,
                    });
                },
                ...components,
            }}
            className={`${markdown} ${className} ${
                useDefaultStyles ? defaultStyles : ''
            } ck-content`}
            {...rest}
        >
            {children}
        </ReactMarkdown>
    );
};

export default Markdown;
