export const breakpoints = {
    foldPhone: 320,
    smallPhone: 375,
    mediumPhone: 420,
    phone: 600,
    tablet: 840,
    iPad: 1024,
    smallLaptop: 1200,
    laptop: 1400,
    mediumDesktop: 1600,
    largeDesktop: 1920,
};
