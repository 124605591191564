export const dataLayerEvents = {
    menuClick: (action: string, label: string) => {
        // @ts-ignore
        dataLayer.push({
            event: 'uaevent',
            ecommerce: 'undefined',
            eventCategory: 'main menu navigation',
            eventAction: `select::${action}`,
            eventLabel: label,
            event_name: 'menu_click',
            click_area: action,
            breadcrumb: label,
        });
    },
    socialClick: (social: string, action: string, url: string) => {
        // @ts-ignore
        dataLayer.push({
            event: 'socialInt',
            ecommerce: 'undefined',
            eventCategory: social,
            eventAction: action,
            eventLabel: url,
            event_name: 'social_interaction',
            social_network: social,
            social_action: action,
        });
    },
    ctaClick: (category: string, ctaName: string, url: string) => {
        // @ts-ignore
        dataLayer.push({
            event: 'uaevent',
            ecommerce: 'undefined',
            eventCategory: category,
            eventAction: `select::${ctaName}`,
            eventLabel: `${ctaName}::${url}`,
            event_name: 'body_button_click',
            module_name: ctaName,
            cta_name: ctaName,
            link_url: url,
        });
    },
    logoClick: () => {
        // @ts-ignore
        dataLayer.push({
            event: 'uaevent',
            ecommerce: 'undefined',
            eventCategory: 'main menu navigation',
            eventAction: `select`,
            eventLabel: `logo`,
            event_name: 'logo_click',
        });
    },
};
