import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Field, FieldAttributes, useField } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import {
    container,
    inputDisabled,
    inputContainer,
    input,
    error,
    errorText,
    showPasswordButton,
    note as noteClass,
    noteDisabled,
    errorOutput,
} from './input.module.scss';

import Eye from '../../assets/images/svg/eye.svg';
import EyeHideLine from '../../assets/images/svg/eye-hide-line.svg';

import { IError } from '../../utils/get-form-errors';
import { TRequiredStarPlacement } from '../../models/form-field.model';
import useTranslations from '../../hooks/use-translations';

import Error from './error';
import IconButton from './icon-button';
import InputLabel from './input-label';
import Tooltip from './tooltip';
import Markdown from '../hoc/markdown';

export type TInputProps = {
    identifier?: string;
    hint?: string;
    hintPlacement?: 'label' | 'input';
    label?: React.ReactNode;
    as?: 'input' | 'textarea';
    isUncontrolled?: boolean;
    beforeSettingUncontrolledValue?(value: string): string | undefined;
    onUncontrolledChange?(value: string): void;
    showError?: boolean;
    icon?: React.SVGFactory;
    iconSide?: 'left' | 'right';
    maxLength?: number;
    isRequired?: boolean;
    requiredPlacement?: TRequiredStarPlacement[];
    submitErrors?: IError[];
    handleSubmitErrors?: (errors: IError[]) => void;
    children?: React.ReactNode;
    note?: string;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
} & Omit<FieldAttributes<Record<string | number, any>>, 'ref'>;

export interface IInputHandle {
    value: string;
    setValue: React.Dispatch<string>;
}

const Input = forwardRef<IInputHandle, TInputProps>(
    (
        {
            className = '',
            name,
            type = 'text',
            id,
            identifier,
            hint,
            hintPlacement = 'label',
            placeholder,
            as = 'input',
            label,
            rows = 3,
            disabled,
            isUncontrolled = false,
            beforeSettingUncontrolledValue,
            onUncontrolledChange,
            showError = true,
            context = 'normal',
            icon,
            iconSide = 'left',
            maxLength,
            isRequired,
            requiredPlacement = ['label'],
            submitErrors,
            handleSubmitErrors,
            min,
            max,
            pattern,
            children,
            note,
            ...rest
        },
        ref
    ) => {
        const [value, setValue] = useState(rest.value || '');
        const t = useTranslations('Input');
        const [, meta] = useField(name);
        const [showPassword, setShowPassword] = useState(false);
        const submitError =
            submitErrors && submitErrors.length > 0 && submitErrors.find((e) => e.field === name);
        const hasError =
            !!(meta.error && meta.touched) ||
            (!!submitError && (!meta.value || !meta.touched || !meta.error));
        const inputId = id ? id : name;
        const Icon = icon;

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: string | undefined = event.target.value;
            if (typeof beforeSettingUncontrolledValue === 'function') {
                newValue = beforeSettingUncontrolledValue(newValue);
                if (newValue === undefined) return;
            }
            setValue(newValue);
            if (typeof onUncontrolledChange !== 'function' || newValue === undefined) return;
            onUncontrolledChange(newValue);
        };

        useImperativeHandle(ref, () => ({
            value,
            setValue,
        }));

        const handleMouseDown = () => setShowPassword(true);
        const handleMouseUp = () => setShowPassword(false);

        useEffect(() => {
            if (submitErrors && submitError && handleSubmitErrors && meta.value) {
                handleSubmitErrors(submitErrors.filter((e) => e !== submitError));
            }
        }, [meta.value]);

        return (
            <div
                className={`${container} ${className} ${disabled ? inputDisabled : ''} ${
                    hasError ? error : ''
                } input`}
            >
                {label && (
                    <InputLabel
                        id={`radio-group-${name}`}
                        as="p"
                        hint={hintPlacement === 'label' ? hint : ''}
                        isRequired={isRequired}
                        isDisabled={disabled}
                        isError={hasError}
                    >
                        {label}
                    </InputLabel>
                )}
                <div className={`${inputContainer} input__container`}>
                    {Icon && (
                        <div className={`input__icon`}>
                            <Icon />
                        </div>
                    )}
                    <Field
                        as={as === 'textarea' ? TextareaAutosize : ''}
                        {...(as === 'textarea' ? { minRows: rows } : {})}
                        id={inputId}
                        name={name}
                        className={`${input} input__input`}
                        placeholder={placeholder}
                        disabled={disabled}
                        type={showPassword ? 'text' : type}
                        maxLength={maxLength}
                        min={min}
                        max={max}
                        pattern={pattern}
                        {...rest}
                        {...(isUncontrolled ? { onChange: handleChange, value } : {})}
                    />
                    {children}
                    {type === 'password' && (
                        <IconButton
                            className={showPasswordButton}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            title={t.showPassword}
                        >
                            {showPassword ? <EyeHideLine /> : <Eye />}
                        </IconButton>
                    )}
                </div>
                {hintPlacement === 'input' && hint && (
                    <Tooltip>
                        <Markdown>{hint}</Markdown>
                    </Tooltip>
                )}
                {maxLength && (
                    <p className={`max-length`}>
                        {meta.value?.length || 0}/{maxLength}
                    </p>
                )}
                {showError && <Error className={errorText} name={name} />}
                {hasError && submitError && <p className={errorOutput}>{submitError.content}</p>}
                {note && (
                    <p className={`${noteClass} ${disabled ? noteDisabled : ''} note`}>{note}</p>
                )}
            </div>
        );
    }
);

export default Input;
