import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';

import {
    error,
    button,
    list,
    listElement,
    listValue,
    inputContainer,
    label as labelClass,
} from './multi-input.module.scss';

import CloseIcon from '../../assets/images/svg/close.svg';

import Error from './error';
import Input, { IInputHandle } from './input';
import IconButton from './icon-button';

export interface IMultiInputProps {
    className?: string;
    name: string;
    id?: string;
    hint?: string;
    label?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    identifier?: string;
    placeholder?: string;
}

const MultiInput: React.FC<IMultiInputProps> = ({
    className = '',
    name,
    hint,
    identifier,
    label,
    isRequired,
    id,
    isDisabled,
    placeholder,
}) => {
    const inputRef = useRef<IInputHandle>(null);
    const [, meta, helpers] = useField(name);
    const [fieldValues, setFieldValues] = useState<string[]>([]);
    const emailRegexWithSpace = /^[\p{L}0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s$/u;

    const handleChange = (value: string) => {
        if (inputRef.current && emailRegexWithSpace.test(value)) {
            const newValues = [...fieldValues, value.replace(' ', '')];
            setFieldValues(newValues);
            helpers.setValue(newValues.join(','));
            inputRef.current.setValue('');
        }
    };

    const handleDelete = (value: string) => {
        return () => {
            const index = fieldValues.indexOf(value);
            const newValues = fieldValues.filter((_, i) => i !== index);
            setFieldValues(newValues);
            helpers.setValue(newValues.join(','));
        };
    };

    useEffect(() => {
        if (inputRef.current && inputRef.current.value !== '') {
            if (emailRegexWithSpace.test(`${inputRef.current.value} `)) {
                handleChange(inputRef.current.value.replace(' ', '') + ' ');
            } else {
                inputRef.current.setValue('');
            }
        }
    }, [inputRef.current?.value]);

    useEffect(() => {
        if (fieldValues.length === 0 && meta.value && meta.value !== '') {
            helpers.setValue(meta.value);
            setFieldValues(meta.value.split(','));
        }
    }, []);

    return (
        <div className={className}>
            <div className={labelClass}>{label}</div>
            <div className={inputContainer}>
                <ul className={list}>
                    {fieldValues.map((value, index) => {
                        return (
                            <li key={`multi-input-value-${index}`} className={listElement}>
                                <p className={listValue}>{value}</p>
                                {!isDisabled && (
                                    <IconButton
                                        type="button"
                                        onClick={handleDelete(value)}
                                        className={button}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </li>
                        );
                    })}
                    <Input
                        ref={inputRef}
                        id={id}
                        name={name}
                        isUncontrolled={true}
                        showError={false}
                        disabled={isDisabled}
                        identifier={identifier}
                        placeholder={placeholder}
                        isRequired={isRequired}
                        hint={hint}
                        onUncontrolledChange={handleChange}
                    />
                </ul>
            </div>
            <Error name={name} className={error} />
        </div>
    );
};

export default MultiInput;
