import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import {
    container,
    tooltip,
    tooltipHint,
    tooltipArrow,
    tooltipClose,
    isActive,
    isVisible,
    top,
    right,
    bottom,
    left,
    transformLeft,
    transformRight,
    transformMiddle,
} from './tooltip.module.scss';

import IconQuestion from '../../assets/images/svg/question.svg';
import IconInfo from '../../assets/images/svg/info.svg';
import IconClose from '../../assets/images/svg/close.svg';

import { breakpoints } from '../../config/breakpoints';

type TIcon = 'info' | 'question' | React.ReactNode;
type TTooltipPosition = 'top' | 'right' | 'bottom' | 'left';

interface ITooltip {
    children: React.ReactNode;
    position?: TTooltipPosition;
    icon?: TIcon;
    className?: string;
    initialWidth?: number;
    desktopOffset?: number;
    mobileOffset?: number;
    allowClick?: boolean;
    root?: React.RefObject<HTMLElement>;
}

const Tooltip: React.FC<ITooltip> = ({
    children,
    initialWidth = 360,
    className = '',
    icon = 'question',
    position = 'bottom',
    desktopOffset = 30,
    mobileOffset = 15,
    allowClick = false,
    root,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const parent = containerRef;
    const [tooltipPosition, setTooltipPosition] = useState({
        x: '0',
        y: '0',
        width: `${initialWidth.toString()}px`,
    });
    const [arrowPosition, setArrowPosition] = useState({ x: '0', y: '0', r: '0deg' });

    const handleTooltipPosition = (props: {
        x: string | undefined;
        y: string | undefined;
        width: string | undefined;
    }) => {
        const { x, y, width } = props;

        setTooltipPosition((prevState) => {
            return {
                x: x ? x : prevState.x,
                y: y ? y : prevState.y,
                width: width ? width : prevState.width,
            };
        });
    };

    const handleArrowPosition = (props: {
        x: string | undefined;
        y: string | undefined;
        r: string | undefined;
    }) => {
        const { x, y, r } = props;

        setArrowPosition((prevState) => {
            return {
                x: x ? x : prevState.x,
                y: y ? y : prevState.y,
                r: r ? r : prevState.r,
            };
        });
    };

    const handleTooltipShow = () => {
        if (!tooltipRef.current?.classList.contains('is-displayed')) {
            tooltipRef.current?.classList.add('is-displayed');
            tooltipRef.current?.classList.add(getPositionClassName(position));
            setTimeout(() => {
                calculateTooltipPosition();
                tooltipRef.current?.classList.add(isVisible);
            }, 30);
        }
    };

    const handleTooltipHide = (e: MouseEvent | SyntheticEvent) => {
        e.stopPropagation();

        if (tooltipRef.current?.classList.contains(isActive)) {
            return;
        }

        tooltipRef.current?.classList.remove(isVisible);
        setTimeout(() => {
            resetTooltip();
        }, 150);
    };

    const handleTooltipClick = () => {
        if (typeof window !== 'undefined' && window.innerWidth > breakpoints.iPad && allowClick) {
            tooltipRef.current?.classList.add('is-displayed');
            setTimeout(() => {
                tooltipRef.current?.classList.add(isActive);
            }, 30);
        }
    };

    const handleTooltipDeactivate = () => {
        if (
            typeof window !== 'undefined' &&
            window.innerWidth > breakpoints.iPad &&
            tooltipRef.current?.classList.contains(isActive) &&
            allowClick
        ) {
            tooltipRef.current?.classList.remove(isActive);
            tooltipRef.current?.classList.remove(isVisible);
            setTimeout(() => {
                resetTooltip();
            }, 150);
        }
    };

    const resetTooltip = () => {
        tooltipRef.current?.classList.remove('is-displayed');
        tooltipRef.current?.classList.remove(left, right, top, bottom);
        tooltipRef.current?.classList.remove(transformLeft, transformMiddle, transformRight);
        handleTooltipPosition({ x: '0', y: '0', width: `${initialWidth.toString()}px` });
        handleArrowPosition({ x: '0', y: '0', r: '0deg' });
    };

    const calculateTooltipPosition = () => {
        const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
        const parentBounds = parent.current?.getBoundingClientRect();
        const tooltipBounds = tooltipRef.current?.getBoundingClientRect();
        const OFFSET = windowWidth <= breakpoints.mediumPhone ? mobileOffset : desktopOffset;

        if (parent.current && tooltipRef.current && parentBounds && tooltipBounds) {
            const localCurrentPosition = getCurrentPosition(
                position,
                root,
                tooltipRef,
                tooltipBounds,
                parentBounds,
                OFFSET
            );

            tooltipRef.current.classList.remove(left, right, top, bottom);
            tooltipRef.current.classList.add(localCurrentPosition);

            getTransformPosition(
                parent,
                tooltipRef,
                root,
                localCurrentPosition,
                OFFSET,
                initialWidth,
                handleTooltipPosition,
                handleArrowPosition
            );
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', () => {
                tooltipRef.current?.classList.remove(isVisible);
                tooltipRef.current?.classList.remove(isActive);
                setTimeout(() => {
                    resetTooltip();
                }, 150);
            });
        }
    }, []);

    return (
        <div
            className={`${className} ${container} tooltip-container`}
            ref={containerRef}
            onMouseLeave={handleTooltipHide}
        >
            {getIcon(icon, handleTooltipShow, handleTooltipClick)}
            <div
                className={`${tooltip} tooltip`}
                ref={tooltipRef}
                style={
                    {
                        '--tooltip-x': tooltipPosition.x,
                        '--tooltip-y': tooltipPosition.y,
                        '--tooltip-width': tooltipPosition.width,
                        maxWidth: initialWidth,
                    } as React.CSSProperties
                }
            >
                <div
                    className={`${tooltipArrow} tooltip-arrow`}
                    style={
                        {
                            '--arrow-x': arrowPosition.x,
                            '--arrow-y': arrowPosition.y,
                            '--arrow-r': arrowPosition.r,
                        } as React.CSSProperties
                    }
                />
                <div className={`${tooltipHint} tooltip-content`}>
                    {allowClick && (
                        <span className={tooltipClose} onClick={handleTooltipDeactivate}>
                            <IconClose />
                        </span>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
};

const getCurrentPosition = (
    position: TTooltipPosition,
    root: React.RefObject<HTMLElement> | undefined,
    tooltip: React.RefObject<HTMLElement>,
    tooltipBounds: DOMRect,
    parentBounds: DOMRect,
    OFFSET: number
) => {
    const rootBounds = root && root.current ? root.current.getBoundingClientRect() : undefined;
    const rootBorderRight = rootBounds ? rootBounds.right : window.innerWidth;
    const rootBorderLeft = rootBounds ? rootBounds.left : 0;
    if (root) {
        OFFSET = 0;
    }

    if (position === 'right' && tooltip.current) {
        if (rootBorderRight - tooltipBounds.right <= OFFSET) {
            return bottom;
        } else if (rootBorderRight - parentBounds.right - tooltip.current.clientWidth > OFFSET) {
            return right;
        }
    } else if (position === 'left' && tooltip.current) {
        if (tooltipBounds.left - OFFSET <= rootBorderLeft) {
            return bottom;
        } else if (tooltip.current.clientWidth + OFFSET < parentBounds.left - rootBorderLeft) {
            return left;
        }
    } else if (position === 'top') {
        return top;
    } else if (position === 'bottom') {
        return bottom;
    }
};

const getTransformPosition = (
    parent: React.RefObject<HTMLElement>,
    tooltipRef: React.RefObject<HTMLElement>,
    root: React.RefObject<HTMLElement> | undefined,
    currentPosition: string,
    OFFSET: number,
    initialWidth: number,
    handleTooltipPosition: (props: {
        x: string | undefined;
        y: string | undefined;
        width: string | undefined;
    }) => void,
    handleArrowPosition: (props: {
        x: string | undefined;
        y: string | undefined;
        r: string | undefined;
    }) => void
) => {
    if (tooltipRef.current && parent.current) {
        if (root) {
            OFFSET = 0;
        }

        if (currentPosition === bottom || currentPosition === top) {
            let transformSide = transformMiddle;
            tooltipRef.current.classList.add(transformSide);

            handleTooltipPosition({
                x: root && root.current ? '0' : '-50px',
                y: '0',
                width:
                    root && root.current
                        ? `${
                              root.current.clientWidth >= initialWidth
                                  ? initialWidth
                                  : root.current.clientWidth
                          }px`
                        : `calc(100vw - ${OFFSET * 2}px)`,
            });

            const rootBounds =
                root && root.current ? root.current.getBoundingClientRect() : undefined;
            const rootBorderRight = rootBounds ? rootBounds.right : window.innerWidth;
            const rootBorderLeft = rootBounds ? rootBounds.left : 0;
            const parentBounds = parent.current?.getBoundingClientRect();
            let tooltipBounds = tooltipRef.current?.getBoundingClientRect();

            if (root && root.current) {
                if (
                    rootBorderRight - parentBounds.left > root.current.clientWidth / 2 &&
                    (tooltipBounds.left < rootBorderLeft || tooltipBounds.right > rootBorderRight)
                ) {
                    transformSide = transformLeft;
                } else if (
                    rootBorderRight - parentBounds.left <= root.current.clientWidth / 2 &&
                    (tooltipBounds.left < rootBorderLeft || tooltipBounds.right > rootBorderRight)
                ) {
                    transformSide = transformRight;
                }
            } else {
                if (
                    parentBounds.left > rootBorderRight - parentBounds.right &&
                    rootBorderRight - parentBounds.right <=
                        tooltipRef.current.clientWidth / 2 + OFFSET
                ) {
                    transformSide = transformLeft;
                } else if (
                    parentBounds.left < rootBorderRight - parentBounds.right &&
                    tooltipBounds.left - OFFSET < rootBorderLeft
                ) {
                    transformSide = transformRight;
                }
            }

            if (transformSide !== transformMiddle) {
                tooltipRef.current.classList.remove(transformMiddle);
                tooltipRef.current.classList.add(transformSide);
            }

            if (parent.current) {
                let tooltipX;
                let tooltipXRoot;
                let tooltipY;
                let tooltipWidth;
                let tooltipWidthRoot;
                let arrowX;
                let arrowXRoot;
                let arrowY;
                let arrowR;
                tooltipBounds = tooltipRef.current?.getBoundingClientRect();

                if (transformSide === transformRight) {
                    tooltipX = `-${parentBounds.left - OFFSET}px`;
                    tooltipXRoot = `-${parentBounds.left - rootBorderLeft}px`;
                    tooltipY = '0';
                    tooltipWidth = `calc(100vw - ${OFFSET * 2}px)`;
                    tooltipWidthRoot =
                        root &&
                        root.current &&
                        `${
                            root.current.clientWidth >= initialWidth
                                ? initialWidth
                                : root.current.clientWidth
                        }px`;

                    arrowX = `${parentBounds.left - OFFSET + parent.current.clientWidth / 2}px`;
                    arrowXRoot =
                        root &&
                        root.current &&
                        `${parentBounds.right - rootBorderLeft - parent.current.clientWidth / 2}px`;
                    arrowY = currentPosition === top ? '50%' : '-50%';
                    arrowR = '45deg';
                } else if (transformSide === transformLeft) {
                    tooltipX = `${rootBorderRight - parentBounds.right - OFFSET}px`;
                    tooltipXRoot =
                        root &&
                        root.current &&
                        `${
                            root.current.clientWidth < initialWidth
                                ? rootBorderLeft -
                                  tooltipBounds.left -
                                  (initialWidth - root.current.clientWidth)
                                : rootBorderLeft - tooltipBounds.left
                        }px`;
                    tooltipY = '0';
                    tooltipWidth = `calc(100vw - ${OFFSET * 2}px)`;
                    tooltipWidthRoot =
                        root &&
                        root.current &&
                        `${
                            root.current.clientWidth >= initialWidth
                                ? initialWidth
                                : root.current.clientWidth
                        }px`;

                    arrowX = `-${
                        rootBorderRight -
                        parentBounds.right -
                        OFFSET +
                        parent.current.clientWidth / 2
                    }px`;
                    arrowXRoot =
                        root &&
                        root.current &&
                        `${
                            tooltipRef.current.clientWidth * -1 +
                            (parentBounds.left - rootBorderLeft) +
                            parent.current.clientWidth / 2 +
                            (root.current.clientWidth < initialWidth
                                ? initialWidth - root.current.clientWidth
                                : 0)
                        }px`;
                    arrowY = currentPosition === top ? '50%' : '-50%';
                    arrowR = '45deg';
                }

                handleTooltipPosition({
                    x: root && root.current && tooltipXRoot ? tooltipXRoot : tooltipX,
                    y: tooltipY,
                    width:
                        root && root.current && tooltipWidthRoot ? tooltipWidthRoot : tooltipWidth,
                });
                handleArrowPosition({
                    x: root && root.current && arrowXRoot ? arrowXRoot : arrowX,
                    y: arrowY,
                    r: arrowR,
                });
            }
        } else {
            handleTooltipPosition({
                x: '0',
                y: '-50%',
                width:
                    root && root.current
                        ? `${
                              root.current.clientWidth >= initialWidth
                                  ? initialWidth
                                  : root.current.clientWidth
                          }px`
                        : `calc(100vw - ${OFFSET * 2}px)`,
            });
            handleArrowPosition({
                x: currentPosition === left ? '-25%' : '-50%',
                y: '-50%',
                r: '-45deg',
            });
        }
    }
};

const getIcon = (icon: TIcon, onMouseEnter: () => void, onClick: () => void) => {
    switch (icon) {
        case 'info':
            return (
                <IconInfo className="tooltip-icon" onMouseEnter={onMouseEnter} onClick={onClick} />
            );
        case 'question':
            return (
                <IconQuestion
                    className="tooltip-icon"
                    onMouseEnter={onMouseEnter}
                    onClick={onClick}
                />
            );
        default:
            return (
                <span className="tooltip-icon" onMouseEnter={onMouseEnter} onClick={onClick}>
                    {icon}
                </span>
            );
    }
};

const getPositionClassName = (position: TTooltipPosition) => {
    switch (position) {
        case 'top':
            return top;
        case 'right':
            return right;
        case 'bottom':
            return bottom;
        case 'left':
            return left;
    }
};

export default Tooltip;
