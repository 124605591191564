// extracted by mini-css-extract-plugin
export var button = "button-module--button--cb4cb";
export var ctaStyle = "button-module--cta-style--6e14e";
export var disabled = "button-module--disabled--6e179";
export var hidePointer = "button-module--hide-pointer--1baee";
export var linkCard = "button-module--link-card--50b5e";
export var loading = "button-module--loading--b00dc";
export var loadingIcon = "button-module--loading-icon--17f2a";
export var mainStyle = "button-module--main-style--25059";
export var normal = "button-module--normal--9fd12";
export var outline = "button-module--outline--45e5b";
export var postCard = "button-module--post-card--6aa1e";
export var productCard = "button-module--product-card--e8332";
export var secondaryStyle = "button-module--secondary-style--bd785";
export var text = "button-module--text--4defd";