import React from 'react';

import { container, text, error, disabled, required, large } from './input-label.module.scss';

import Tooltip from './tooltip';

type TInputLabelSize = 'normal' | 'large';

export interface IInputLabelProps {
    className?: string;
    id?: string;
    htmlFor?: string;
    children?: React.ReactNode;
    hint?: React.ReactNode;
    as?: 'label' | 'p';
    isDisabled?: boolean;
    isError?: boolean;
    isRequired?: boolean;
    size?: TInputLabelSize;
}

const InputLabel: React.FC<IInputLabelProps> = ({
    className = '',
    id,
    htmlFor,
    hint,
    as = 'label',
    isDisabled,
    isError,
    isRequired,
    size = 'normal',
    children,
}) => {
    const sizeClass = sizeClasses[size];

    return (
        <div
            className={`${container} ${className} ${isDisabled ? disabled : ''} ${
                isError ? error : ''
            } ${isRequired ? required : ''} ${sizeClass} label`}
        >
            {as === 'label' && (
                <label htmlFor={htmlFor} className={text} id={id}>
                    {children}
                </label>
            )}
            {as === 'p' && (
                <p className={text} id={id}>
                    {children}
                </p>
            )}
            {hint && <Tooltip>{hint}</Tooltip>}
        </div>
    );
};

const sizeClasses: Record<TInputLabelSize, string> = {
    normal: '',
    large: large,
};

export default InputLabel;
