import React from 'react';
import { Link } from 'gatsby';

import { useIsLinkInternal } from '../../hooks/use-is-link-internal';

export interface IMarkdownLinkProps {
    href?: string;
    target?: React.HTMLAttributeAnchorTarget;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    style?: React.CSSProperties;
}

export default function MarkdownLink({
    href,
    target,
    children,
    className = '',
    style,
}: IMarkdownLinkProps) {
    const isLinkInternal = useIsLinkInternal(href);

    if (!href) {
        return (
            <span className={className} style={style}>
                {children}
            </span>
        );
    }

    if (isLinkInternal && target !== '_blank') {
        return (
            <Link className={className} to={href} style={style}>
                {children}
            </Link>
        );
    }

    return (
        <a
            href={href}
            className={className}
            style={style}
            target="_blank"
            rel="noreferrer noopener"
        >
            {children}
        </a>
    );
}
