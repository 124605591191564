import React from 'react';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

import { useMedia } from './use-media';

import { BaseImage, IBaseImageProps } from './base-image';

export interface IImageProps extends Omit<IBaseImageProps, 'image' | 'alt' | 'url' | 'caption'> {
    media: IMedia[] | undefined;
    relation?: string;
    fallback?: string;
    useFallback?: boolean;
}

export const Image: React.FC<IImageProps> = ({
    media,
    relation = 'main-image',
    fallback = 'main-image',
    useFallback = true,
    ...rest
}) => {
    const { image, alt, url, caption } = useMedia({ media, relation, fallback, useFallback });

    return <BaseImage image={image} alt={alt} url={url} caption={caption} {...rest} />;
};
